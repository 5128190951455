import React from 'react'
import '../../styles/topBar/profileIcon.css'

const ProfileIconTopBar = (props) => {
    return (
        <button className="icon-circle">
            <div className='icon-text'>
                {props.initials}
            </div>
        </button>
    )
}

export default ProfileIconTopBar