import React, { useEffect, useRef, useState } from 'react';
import '../../../../styles/dashboard/modal.css'; // Import your CSS for modal styling
import Modal from 'react-modal';
import { closeAddMachineModal } from '../../../../store/actions/dashboard';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchAllGymsData } from '../../../../store/actions/gyms';
import { fetchAllOwnersData } from '../../../../store/actions/owners';


Modal.setAppElement('#root'); // Set the root element as the app element

const AddMachineModal = (props) => {
    const [nameGym, setNameGym] = useState("")

    const ref = useRef()
    const [selectedGym, setSelectedGym] = useState(null)
    const [selectedGymId, setSelectedGymId] = useState(null)
    const [newGym, setNewGym] = useState(false)

    const [selectedOwner, setSelectedOwner] = useState(null)
    const [selectedOwnerId, setSelectedOwnerId] = useState(null)
    const [newOwner, setNewOwner] = useState(false)

    const [machineId, setMachineId] = useState('')
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneGym, setPhoneGym] = useState('')
    const [nameOwner, setNameOwner] = useState('')
    const [phoneOwner, setPhoneOwner] = useState('')

    const [isFocusedMachineId, setIsFocusedMachineId] = useState(false)
    const [isFocusedName, setIsFocusedName] = useState(false)
    const [isFocusedAddress, setIsFocusedAddress] = useState(false)
    const [isFocusedEmail, setIsFocusedEmail] = useState(false)
    const [isFocusedPhoneGym, setIsFocusedPhoneGym] = useState(false)

    const [isFocusedNameOwner, setIsFocusedNameOwner] = useState(false)
    const [isFocusedPhoneOwner, setIsFocusedPhoneOwner] = useState(false)

    const [isGymsListOpen, setIsGymsListOpen] = useState(false)
    const [isOwnersListOpen, setIsOwnersListOpen] = useState(false)

    const changeOnBlurMachineId = () => {
        setIsFocusedMachineId(false)
    }

    const changeOnFocusMachineId = () => {
        setIsFocusedMachineId(true)
    }

    const changeOnBlurName = () => {
        setIsFocusedName(false)
    }

    const changeOnFocusName = () => {
        setIsFocusedName(true)
    }
    
    const changeOnBlurAddress = () => {
        setIsFocusedAddress(false)
    }

    const changeOnFocusAddress = () => {
        setIsFocusedAddress(true)
    }

    const changeOnBlurEmail = () => {
        setIsFocusedEmail(false)
    }

    const changeOnFocusEmail = () => {
        setIsFocusedEmail(true)
    }

    const changeOnBlurPhoneGym = () => {
        setIsFocusedPhoneGym(false)
    }

    const changeOnFocusPhoneGym = () => {
        setIsFocusedPhoneGym(true)
    }

    const handleMachineIdChange = (machineId) => {
        setMachineId(machineId)
    }

    const handleNameChange = (name) => {
        setName(name)
        const element = props.gyms.find(item => item.name === name);
        if (element) {
            setNameGym(name)
        } else {
            setNewGym(true)
        }
    }

    const handleAddressChange = (address) => {
        setAddress(address)
    }

    const handleEmailChange = (email) => {
        setEmail(email)
    }

    const handlePhoneGymChange = (phone) => {
        setPhoneGym(phone)
    }

    // info about the owner
    const handleNameOwnerChange = (name) => {
        setNameOwner(name)
    }

    const handlePhoneOwnerChange = (phone) => {
        setPhoneOwner(phone)
    }

    // logic when selecting gym 
    const handleSelectGym = (gymName, id) => {
        setSelectedGym(gymName)
        setSelectedGymId(id)
        if (id ===  0) {
            setNewGym(true)
        }
        setIsGymsListOpen(false)
    }

    // logic when selecting owner 
    const handleSelectOwner = (ownerName, id) => {
        setSelectedOwner(ownerName)
        setSelectedOwnerId(id)

        if (id === 0) {
            setNewOwner(true)
        }
        setIsOwnersListOpen(false)
    }

    const handleCloseAddMachineModal = () => {
        props.closeAddMachineModal()
    }

    useEffect(() => {
        if (!props.gymsFetched) {
            props.fetchAllGymsData()
        }
    }, props.gymsFetched)

    useEffect(() => {
        if (!props.ownersFetched) {
            props.fetchAllOwnersData()
        }
    }, props.ownersFetched)


    if (props.gyms && props.owners) {
        return (
            <Modal
            isOpen={props.isAddMachineModalOpened}
            className="modal-content"
            overlayClassName="modal-overlay"
            >
            <div className="modal-container">
                <div className='close-window-container'>
                    <button onClick={() => props.closeAddMachineModal()} className='close-window'>
                        x
                    </button>
                </div>
                <div className="title-modal">Ajouter une machine </div>
                <div className='content-modal-container'>
                    <div className="content-modal-text space-inputs">
                        <div>
                            Machine Id: 
                        </div>
                        <div className={`input-box-modal ${isFocusedMachineId ? 'focused-modal' : ''}`}>
                            <input
                                className="text-input-modal"
                                type="text"
                                autoComplete="off"
                                placeholder=""
                                value={machineId}
                                onChange={(e) => handleMachineIdChange(e.target.value)}
                                onFocus={changeOnFocusMachineId}
                                onBlur={changeOnBlurMachineId}
                            />
                        </div>
                    </div>
                    <div className="content-modal-text space-inputs">
                        <div>
                            Nom: 
                        </div>
                        <div className={`input-box-modal ${isFocusedName ? 'focused-modal' : ''}`}>
                            <input
                                className="text-input-modal"
                                type="text"
                                autoComplete="off"
                                placeholder=""
                                value={name}
                                onChange={(e) => handleNameChange(e.target.value)}
                                onFocus={changeOnFocusName}
                                onBlur={changeOnBlurName}
                            />
                        </div>
                    </div>
                    <div className="content-modal-text space-inputs">
                        <div>
                            Addresse: 
                        </div>
                        <div className={`input-box-modal ${isFocusedAddress ? 'focused-modal' : ''}`}>
                            <input
                                className="text-input-modal"
                                type="text"
                                autoComplete="off"
                                placeholder=""
                                value={address}
                                onChange={(e) => handleAddressChange(e.target.value)}
                                onFocus={changeOnFocusAddress}
                                onBlur={changeOnBlurAddress}
                            />
                        </div>
                    </div>
                    <div className="choice-modal">
                        <div className='choice-membership-modal-text'>
                            Salle de sport:
                        </div>
                        <div className='dropdown-menu-container' ref={ref}>
                            <button onClick={() => setIsGymsListOpen(!isGymsListOpen)} className='dropdown-menu-selected-item'>
                                    <div className='dropdown-menu-selected-item-text'>
                                        {selectedGym}
                                    </div>
                                    { isGymsListOpen ? 
                                        (
                                            <FontAwesomeIcon icon="fa-solid fa-chevron-up" className='chevron-selected-item'/>
                                        ) :
                                        (
                                            <FontAwesomeIcon icon="fa-solid fa-chevron-down" className='chevron-selected-item'/>
                                        )
                                    }
                            </button>
                            { isGymsListOpen && (
                                <div className='dropdown-menu-list-container'>
                                    { props.gyms.map(gym => (
                                        (gym.name !== selectedGym) && (
                                            <button key={gym.id} onClick={() => handleSelectGym(gym.name, gym.id)} className='dropdown-menu-list-items'>
                                                <div className='dropdown-menu-list-items-text'>
                                                    { gym.name }
                                                </div>
                                            </button>
                                        )
                                    ))
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                    {
                                newGym && (
                                    <div className='box-additional-info'>
                                        <div className="content-modal-text space-inputs">
                                            <div>
                                                Email: 
                                            </div>
                                            <div className={`input-box-modal ${isFocusedEmail ? 'focused-modal' : ''}`}>
                                                <input
                                                    className="text-input-modal"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    value={email}
                                                    onChange={(e) => handleEmailChange(e.target.value)}
                                                    onFocus={changeOnFocusEmail}
                                                    onBlur={changeOnBlurEmail}
                                                />
                                            </div>
                                        </div>
                                        <div className="content-modal-text space-inputs">
                                            <div>
                                                Téléphone: 
                                            </div>
                                            <div className={`input-box-modal ${isFocusedPhoneGym ? 'focused-modal' : ''}`}>
                                                <input
                                                    className="text-input-modal"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    value={phoneOwner}
                                                    onChange={(e) => handlePhoneGymChange(e.target.value)}
                                                    onFocus={changeOnFocusPhoneGym}
                                                    onBlur={changeOnBlurPhoneGym}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                    <div className="choice-modal">
                        <div className='choice-membership-modal-text'>
                            Propriétaire:
                        </div>
                        <div className='dropdown-menu-container' ref={ref}>
                            <button onClick={() => setIsOwnersListOpen(!isOwnersListOpen)} className='dropdown-menu-selected-item'>
                                    <div className='dropdown-menu-selected-item-text'>
                                        {selectedOwner}
                                    </div>
                                    { isGymsListOpen ? 
                                        (
                                            <FontAwesomeIcon icon="fa-solid fa-chevron-up" className='chevron-selected-item'/>
                                        ) :
                                        (
                                            <FontAwesomeIcon icon="fa-solid fa-chevron-down" className='chevron-selected-item'/>
                                        )
                                    }
                            </button>
                            { isOwnersListOpen && (
                                <div className='dropdown-menu-list-container'>
                                    { props.owners.map(owner => (
                                        (owner.name !== selectedOwner) && (
                                            <button key={owner.id} onClick={() => handleSelectOwner(owner.name, owner.id)} className='dropdown-menu-list-items'>
                                                <div className='dropdown-menu-list-items-text'>
                                                    { owner.name }
                                                </div>
                                            </button>
                                        )
                                        
                                    ))
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='modal-button-container'>
                    <button onClick={() => handleCloseAddMachineModal()} className='validate-button'>Valider</button>
                </div>
            </div>
            </Modal>
        );
    }
    
};

const mapStateToProps = (state) => {
    return {
        isAddMachineModalOpened: state.dashboardState.isAddMachineModalOpened, 
        gymsFetched: state.gymState.gymsFetched, 
        gyms: state.gymState.gyms,
        ownersFetched: state.ownerState.ownersFetched, 
        owners: state.ownerState.owners
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeAddMachineModal: () => dispatch(closeAddMachineModal()), 
        fetchAllGymsData: () => dispatch(fetchAllGymsData()), 
        fetchAllOwnersData: () => dispatch(fetchAllOwnersData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMachineModal)
