export const openAddMachineModal = () => {
    return (dispatch) => {
        dispatch({
            type: 'OPEN_ADD_MACHINE_MODAL',
        })
    }
}

export const closeAddMachineModal = () => {
    return (dispatch) => {
        dispatch({
            type: 'CLOSE_ADD_MACHINE_MODAL',
        })
    }
}
