
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export function setUserId(userId) {
    return ((dispatch) => {
        dispatch({type: 'SET_USER_ID', userId: userId})
    })
}


export function fetchInfoUser(userId) {
    // we fetch all the data for a specific user
    return ((dispatch) => {
        const userRef = firebase.firestore().collection("users").doc(userId)

        userRef.onSnapshot((documentSnapshot, error) => {
            if (documentSnapshot.exists) {
                const data = documentSnapshot.data()

                // now we want to extract the number of current items in the cart 
                var countCurrentOrders;
                userRef.collection("currentOrders")
                .onSnapshot((snapshot) => {
                    countCurrentOrders = snapshot.size
                    // the dispatch needs to be inside the onSnapshot method otherwise, we can't access the snapshot data
                    dispatch({ type: 'FETCH_USER_DATA', currentUser: { 
                                                                        firstName: data['firstName'], 
                                                                        lastName: data['lastName'],
                                                                        email: data["email"],
                                                                        stripeCustomerId: data["stripeCustomerId"],
                                                                        countCurrentOrders: countCurrentOrders, 
                                                                    } 
                            })
                })
            }
            else {
                dispatch({type: 'ERROR_FETCHING_USER_DATA', error: error, userId: userId})
            }
        })
    })
}