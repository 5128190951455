import React from 'react'
import '../../../styles/dashboard/dashboardPage.css'
import RowTableMainDashboard from './rowTableMainDashboard'
import { openAddMachineModal } from '../../../store/actions/dashboard'
import { connect } from 'react-redux';

const AllMachineMainDashboard = (props) => {

    const handleOpenModalAddMachine = () => {
        props.openAddMachineModal()
    }

    return (
        <div className='main-container-content-wrapper'>
            <div className='title-main-dashboard-container'>
                <div className='main-title-dashboard'>
                    Suivi machines
                </div>
                <div onClick={handleOpenModalAddMachine} className='add-item-container'>
                    <div className='add-item-text'>
                        +
                    </div>
                </div>
            </div>
            <div className='table-main-dashboard'>
                {/* Your table content */}
                <RowTableMainDashboard isTitle={true} key={0}/>
                { props.allMachinesData.map((machineData, index) => (
                    <RowTableMainDashboard isTitle={false} machineData={machineData} lastRow={index === props.allMachinesData.length - 1} key={index}/>
                ))}
            </div>
        </div>
    )
}

  
const mapDispatchProps = (dispatch) => {
    return { 
        openAddMachineModal: () => { dispatch(openAddMachineModal()) }
    };
}
  
export default connect(null, mapDispatchProps)(AllMachineMainDashboard);
