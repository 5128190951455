
import './App.css';
import './index.css';
import NavBar from './navigation/navbar';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import MainDashboardPage from './pages/dashboard/mainDashboardPage';

import { connect } from 'react-redux';
import SignInPage from "./pages/auth/signIn"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { firebaseConfig } from './firebase';
import { useEffect, useState } from 'react';
import { setUserId } from './store/actions/user';
import LoadingSpinner from './pages/loadingSpinner';
import MachineTechnicalDetailsDashboardPage from './pages/dashboard/machineTechnicalDetailsDashboardPage';

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig)
}

const ProtectedRoute = ({children, auth}) => {
  let location = useLocation();

  if(!auth) {
      return <Navigate to="/signIn" state={{ from: location}} replace />
  }
return children

};

const App = ({userId, setUserId}) => {
  // Set an initializing state whilst Firebase connects
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const authStateChanged = (user) => {
      if (!user) {
        setLoaded(true);
        setUser(null)
        setUserId(null);
      } else {
        setUserId(user.uid);
        setUser(user)
        setTimeout(() => {
          setLoaded(true); // Set loaded to true after a 1-second delay
        }, 1000);
      }
    };

    const unsubscribe = firebase.auth().onAuthStateChanged(authStateChanged);

    return () => {
      unsubscribe(); // Unsubscribe when component unmounts
    };
  }, [setUserId, user]);


  if (!loaded) {
    return <LoadingSpinner />
  }


  return (
      <BrowserRouter>
        <div className="App">
          <main className='content'>
            <Routes>
              <Route index path="/signIn" element={<SignInPage />} />
              <Route exact path="/" element={
                <ProtectedRoute auth={(userId !== null)}>
                    <div className='App-container'>
                      <NavBar/>
                      <MainDashboardPage />
                    </div>
                </ProtectedRoute>
              }/>
              <Route path="/machineDetails/:machineId" element={
                <ProtectedRoute auth={(userId !== null)}>
                    <div className='App-container'>
                      <NavBar/>
                      <MachineTechnicalDetailsDashboardPage />
                    </div>
                </ProtectedRoute>
              }/>
              <Route path="*" element={
                <ProtectedRoute auth={(userId !== null)}>
                </ProtectedRoute>
              }/>
            </Routes>
          </main>
        </div>
      </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userState.userId, 
  validUser: state.userState.validUser
})

const mapDispatchProps = (dispatch) => {
  return { 
      setUserId: (userId) => { dispatch(setUserId(userId)) },
  };
}


export default connect(mapStateToProps, mapDispatchProps)(App);
library.add(fas)
