import React, { useEffect, useState } from 'react'
import '../../../styles/dashboard/mainDashboard/rowTableMainDashboard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const RowTableMainDashboard = (props) => {

    const {
        isTitle,
        lastRow, 
        machineData, 
    } = props

    const navigate = useNavigate()
    const [lastWeekDate, setLastWeekDate] = useState(null)
    const [currentDate, setCurrentDate] = useState(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Update windowWidth when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    useEffect(() => {
        const currentDateTimeStamp = new Date()
        const formattedCurrentDate = `${currentDateTimeStamp.getDate()}/${currentDateTimeStamp.getMonth() + 1}/${currentDateTimeStamp.getFullYear() % 100}`;
        
        // Calculate the timestamp for a week ago
        const weekAgoTimestamp = currentDateTimeStamp.getTime() - 7 * 24 * 60 * 60 * 1000;
    
        // Create a new Date object for a week ago
        const weekAgoDate = new Date(weekAgoTimestamp);
    
        // Format the date as DD/MM/YY
        const formattedLastWeekDate = `${weekAgoDate.getDate()}/${weekAgoDate.getMonth() + 1}/${weekAgoDate.getFullYear() % 100}`;
        
        setCurrentDate(formattedCurrentDate)
        setLastWeekDate(formattedLastWeekDate)
    }, []);

    
    const handleClick = (event) => {
        navigate("/machineDetails/" + machineData.id)
        // Perform your navigation logic here
    };

    return (
        <div className="row-container">
            { isTitle ?
                (
                    <div className='row-title'>
                        { (windowWidth > 1180) && (
                            <div className='row-content-reduced'>
                                <div className='id-machine'>
                                    <div className='title-text-container'>
                                        Id machine
                                    </div>
                                </div>
                                <div className='vertical-line'>
                                </div>
                            </div>
                        ) }
                        
                        <div className='gym-state'>
                            <div className='title-text-container'>
                                État
                            </div>
                        </div>
                        <div className='vertical-line'>
                        </div>
                        <div className='gym-name'>
                            <div className='title-text-container'>
                                Nom salle
                            </div>
                        </div>
                        {(windowWidth > 980) && 
                            <div className='row-content-reduced'>
                                <div className='vertical-line'>
                                </div>
                                <div className='gym-tel'>
                                    <div className='title-text-container'>
                                        Tel Salle
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(windowWidth > 350) && 
                            <div className='row-content-reduced'>
                                <div className='vertical-line'>
                                </div>
                                <div className='localisation'>
                                    <div className='title-text-container'>
                                        Localisation
                                    </div>
                                </div>
                            </div>
                        }
                        {(windowWidth > 1080) && 
                            (
                            <div className='row-content-reduced'>
                                <div className='vertical-line'>
                                </div>
                                <div className='name-owner'>
                                    <div className='title-text-container'>
                                        Nom proprietaire
                                    </div>
                                </div>
                            </div>
                            )
                        }
                        
                        {(windowWidth > 550) && 
                            <div className='row-content-reduced'>
                                <div className='vertical-line'>
                                </div>
                                <div className='tel-owner'>
                                    <div className='title-text-container'>
                                        Tel proprietaire
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(windowWidth > 900) && 
                            (
                                <div className='row-content-reduced'>
                                    <div className='vertical-line'>
                                    </div>
                                    <div className='complaints-last-week'>
                                        <div className='title-text-container'>
                                            Plaintes {lastWeekDate} - {currentDate}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                ) : 
                (
                    <div onClick={handleClick} className={`row-content main-dashboard ${lastRow ? "last-row" : ""}`}>
                        {windowWidth > 1180 && (
                            <div className='row-content-reduced'>
                                <div className='id-machine'>
                                    <div className='row-text'>
                                        {machineData.id}
                                    </div>
                                </div>
                                <div className='vertical-line'></div>
                            </div>
                        )}
                        <div className='gym-state'>
                            {machineData.isActive ? 
                            (
                                <div className='icon-valid'>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                            ): 
                            (
                                <div className='icon-nonValid'>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </div>
                            )
                            }
                        </div>
                        <div className='vertical-line'>
                        </div>
                        <div className='gym-name'>
                            <span className='row-text'>
                                {machineData.nameGym}
                            </span>
                        </div>
                        {(windowWidth > 980) && 
                            <div className='row-content-reduced'>
                                <div className='vertical-line'>
                                </div>
                                <div className='gym-tel'>
                                    <div className='row-text'>
                                        {machineData.phoneGym}
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(windowWidth > 350) && 
                            <div className='row-content-reduced'>
                                <div className='vertical-line'>
                                </div>
                                <div className='localisation'>
                                    <div className='row-text'>
                                        {machineData.addressGym}
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(windowWidth > 1080) && 
                            <div className='row-content-reduced'>
                                <div className='vertical-line'>
                                </div>
                                <div className='name-owner'>
                                    <div className='row-text'>
                                        {machineData.nameOwner}
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(windowWidth > 550) && 
                            <div className='row-content-reduced'>
                                <div className='vertical-line'>
                                </div>
                                <div className='tel-owner'>
                                    <div className='row-text'>
                                    {machineData.phoneOwner}
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {(windowWidth > 900) && 
                            <div className='row-content-reduced'>
                                <div className='vertical-line'>
                                </div>
                                <div className='complaints-last-week'>
                                    <div className='row-text'>
                                        {machineData.complaints.length}
                                    </div>
                                </div>
                            </div>
                        }
                        
                    </div>
                ) 
            }
        </div>

    )
}
  
  
export default RowTableMainDashboard;
