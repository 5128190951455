const initState = {
    gyms: null,
    gymsFetched: false
}

const gymReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_GYMS_INFO':
            return {
                ...state,
                gyms: action.gyms, 
                gymsFetched: true
            };
        case 'ERROR_FETCHING_GYMS_INFO': 
            return {
                ...state, 
                gymsFetched: false
            }
        default:
            return state
    }
}

export default gymReducer