import React from 'react'
import '../../../styles/dashboard/dashboardPage.css'
import RowTableTechnicalDashboard from './rowTableTechnicalDashboard'

const ComplaintDashBoardMachines = (props) => {
    return (
        <div className='main-container-content-wrapper'>
            <div className='sub-title'>
                1. Plaintes machines
            </div>
            <div className='table-complaint-dashboard'>
                {/* Your table content */}
                <RowTableTechnicalDashboard isTitle={true} key={0}/>
                { props.machineDataComplaints.map((complaintData, index) => (
                    <RowTableTechnicalDashboard isTitle={false} complaintData={complaintData} lastRow={index === props.machineDataComplaints.length - 1} key={index}/>
                ))}
            </div>
        </div>
    )
}
  
export default (ComplaintDashBoardMachines);
