import { combineReducers } from "redux";
import dashboardReducer from "./dashboardReducer";
import machineReducer from "./machineReducer";
import userReducer from "./userReducer";
import gymReducer from "./gymReducer";
import ownerReducer from "./ownerReducer";


const rootReducer = combineReducers({
    userState: userReducer,
    dashboardState: dashboardReducer,
    machineState: machineReducer, 
    gymState: gymReducer, 
    ownerState: ownerReducer
})

export default rootReducer;