import React, { useEffect, useRef, useState } from 'react'
import '../../styles/dashboard/dashboardPage.css'
import TopBar from '../../utils/topBar/topBar'
import ComplaintDashBoardMachines from '../../utils/dashboard/technicalDashboard/complaintDashboard';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { changeIsMachineActive, fetchAllMachinesData } from '../../store/actions/machine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const MachineTechnicalDetailsDashboardPage = (props) => {
    const {machineId} = useParams()
    const [selectedMachineData, setSelectedMachineData] = useState(null)
    const [isMachineActiveMenuOpen, setIsMachineActiveMenuOpen] = useState(false)

    const ref = useRef()

    useEffect(() => {
        if (props.allMachinesData || props.allMachinesDataFetched) {
            const machineData = props.allMachinesData.find(
                (machine) => machine.id === machineId
            );
            setSelectedMachineData(machineData)
        } else {
            props.fetchAllMachinesData()
        }
    }, [machineId, props.allMachinesDataFetched])
    
    useEffect(() => {
        if (!props.allMachinesDataFetched) {
            props.fetchAllMachinesData()
        }   
    }, [props.allMachinesDataFetched])

    const handleIsActive = (isActive) => {
        props.changeIsMachineActive(machineId, isActive)
        setIsMachineActiveMenuOpen(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsMachineActiveMenuOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])

    if (selectedMachineData) {
        return (
            <div className='main-container'>
                <div className='topBar-main-container'>
                    <div className='topBar-left-container'></div>
                    <TopBar />
                </div>
                <div className='main-title'>
                    Machine {selectedMachineData.name}
                </div>
                <div className='main-container-content scrollable'>
                    <div className='details-machine'>
                        <div>
                            <div className='details-machine-content'>
                                <div className='details-machine-content-name-section'>
                                    Machine Id:
                                </div>
                                <div className='details-machine-content-value-section'>
                                    {selectedMachineData.id}
                                </div>
                            </div>
                            <div className='details-machine-content'>
                                <div className='details-machine-content-name-section'>
                                    Gym:
                                </div>
                                <div className='details-machine-content-value-section'>
                                    {selectedMachineData.nameGym}
                                </div>
                            </div>
                            <div className='details-machine-content'>
                                <div className='details-machine-content-name-section'>
                                    Adresse:
                                </div>
                                <div className='details-machine-content-value-section'>
                                    {selectedMachineData.addressGym}
                                </div>
                            </div>
                            <div className='details-machine-content'>
                                <div className='details-machine-content-name-section'>
                                    Tel. Gym:
                                </div>
                                <div className='details-machine-content-value-section'>
                                    {selectedMachineData.phoneGym}
                                </div>
                            </div>
                            <div className='details-machine-content'>
                                <div className='details-machine-content-name-section'>
                                    Propriétaire:
                                </div>
                                <div className='details-machine-content-value-section'>
                                    {selectedMachineData.nameOwner}
                                </div>
                            </div>
                            <div className='details-machine-content'>
                                <div className='details-machine-content-name-section'>
                                    Tél. Propriétaire:
                                </div>
                                <div className='details-machine-content-value-section'>
                                    {selectedMachineData.phoneOwner}
                                </div>
                            </div>
                            <div className='details-machine-content menu-active'>
                                <div className='details-machine-content-name-section'>
                                    Active
                                </div>
                                <div className='details-machine-content-value-section'>
                                    <div className='dropdown-is-active-menu-container' ref={ref}>
                                        <button onClick={() => setIsMachineActiveMenuOpen(!isMachineActiveMenuOpen)} className='dropdown-menu-selected-item'>
                                                <div className='dropdown-is-active-menu-selected-item-text'>
                                                    {selectedMachineData.isActive ? "Oui" : "Non"}
                                                </div>
                                                { isMachineActiveMenuOpen ? 
                                                    (
                                                        <FontAwesomeIcon icon="fa-solid fa-chevron-up" className='chevron-selected-item'/>
                                                    ) :
                                                    (
                                                        <FontAwesomeIcon icon="fa-solid fa-chevron-down" className='chevron-selected-item'/>
                                                    )
                                                }
                                        </button>
                                        { isMachineActiveMenuOpen && (
                                            <div className='dropdown-menu-list-container'>
                                                <button onClick={() => handleIsActive(true)} className='dropdown-menu-list-items'>
                                                    <div className='dropdown-menu-list-items-text'>
                                                        Oui
                                                    </div>
                                                </button>
                                                <button onClick={() => handleIsActive(false)} className='dropdown-menu-list-items'>
                                                    <div className='dropdown-menu-list-items-text'>
                                                        Non
                                                    </div>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ComplaintDashBoardMachines machineDataComplaints={selectedMachineData.complaints}/>
                </div>
            </div>
        )
    }
    
}

const mapStateToProps = (state) => ({
    allMachinesData: state.machineState.allMachinesData, 
    allMachinesDataFetched: state.machineState.allMachinesDataFetched
})

const mapDispatchProps = (dispatch) => {
    return { 
        fetchAllMachinesData: (userId) => { dispatch(fetchAllMachinesData(userId)) }, 
        changeIsMachineActive: (machineId, isActive) => { dispatch(changeIsMachineActive(machineId, isActive))}
    };
}

export default connect(mapStateToProps, mapDispatchProps)(MachineTechnicalDetailsDashboardPage)
