import firebase from 'firebase/compat/app';
import { connect } from 'react-redux';
import React, { useState } from 'react'
import { setUserId } from '../../store/actions/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/auth/authPage.css'
import Logo from "../../assets/images/logo.png"
import { useNavigate } from 'react-router-dom';


const SignInPage = (props) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
    const [isFocusedEmail, setIsFocusedEmail] = useState(false)
    const [isFocusedPassword, setIsFocusedPassword] = useState(false)

    
    const changeOnBlurEmail = () => {
        setIsFocusedEmail(false)
    }

    const changeOnFocusEmail = () => {
        setIsFocusedEmail(true)
    }

    const changeOnBlurPassword = () => {
        setIsFocusedPassword(false)
    }

    const changeOnFocusPassword = () => {
        setIsFocusedPassword(true)
    }

    const onSignIn = () => {
        if ((email.length === 0) || (password.length === 0)) {
            toast.error('Veuillez remplir tous les champs');
            return
        }
        firebase.firestore().collection("intranetDashboardUsers")
        .where("email", "==", email)
        .get()
        .then((snapshot) => {
            if (snapshot.docs.length > 0) {  
                firebase.auth().signInWithEmailAndPassword(email, password)
                .then((userCredential) => {
                    var user = userCredential.user
                    props.setUserId(user.uid)
                    navigate("/")
                })
                .catch((err) => {
                    setPassword('')
                    if (err.code === "auth/invalid-email") {
                        toast.error('Adresse email incorrecte');
                    }
                    else if (err.code === "auth/user-not-found") {
                        toast.error('Aucun utilisateur trouvé');
                    } else {
                        toast.error('Veuillez réessayer');
                    }
                })
            }
            else {
                
                toast.error('Aucun utilisateur trouvé');
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const handleEmailChange = (email) => {
        setEmail(email)
    }

    const handlePasswordChange = (password) => {
        setPassword(password)
    }

    return (
        <div className='main-container'>
            <div className='main-container-center'>
                <div className='container-content'>
                <img
                    className="logo"
                    src={Logo}
                    alt="Logo"
                />
                <div>
                    <div className={`inputBox ${isFocusedEmail ? 'focused' : ''}`}>
                        <input
                            className="textInput"
                            type="email"
                            placeholder="email"
                            value={email}
                            onChange={(e) => handleEmailChange(e.target.value)}
                            onFocus={changeOnFocusEmail}
                            onBlur={changeOnBlurEmail}
                        />
                    </div>
                    <div className={`inputBox ${isFocusedPassword ? 'focused' : ''}`}>
                        <input
                            className="textInput"
                            type="password"
                            placeholder="mot de passe"
                            value={password}
                            onChange={(e) => handlePasswordChange(e.target.value)}
                            onFocus={changeOnFocusPassword}
                            onBlur={changeOnBlurPassword}
                        />
                    </div>
                    <button className="connectButton" onClick={onSignIn}>
                        Se connecter
                    </button>
                    <div className="forgotPasswordText" onClick={() => props.navigation.navigate('ResetPassword')}>
                        Mot de passe oublié ?
                    </div>
                    <ToastContainer autoClose={3000} />
                </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
})
  
const mapDispatchProps = (dispatch) => {
    return { 
        setUserId: (userId) => { dispatch(setUserId(userId)) },
    };
}
  
export default connect(mapStateToProps, mapDispatchProps)(SignInPage);

