const initState = {
    owners: null,
    ownersFetched: false
}

const ownerReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_OWNERS_INFO':
            return {
                ...state,
                owners: action.owners, 
                ownersFetched: true
            };
        case 'ERROR_FETCHING_OWNERS_INFO': 
            return {
                ...state, 
                ownersFetched: false
            }
        default:
            return state
    }
}

export default ownerReducer