import React from 'react'
import '../../../styles/dashboard/technicalDashboard/rowTableTechnicalDashboard.css'
import { useState } from 'react';
import { useEffect } from 'react';


const RowTableTechnicalDashboard = (props) => {

    const {
        isTitle,
        lastRow, 
        complaintData, 
    } = props

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    // Update windowWidth when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="row-container">
            { isTitle ?
                (
                    <div className='row-title'>
                        {(windowWidth > 950) && (
                            <div className='row-content-reduced'>
                                <div className='id-complaint'>
                                    <div className='title-text-container'>
                                        Id Plainte
                                    </div>
                                </div>
                                <div className='vertical-line'>
                                </div>
                            </div>
                        )}
                        
                        {(windowWidth > 850) && ( 
                            <div className='row-content-reduced'>
                                <div className='type-id-complaint'>
                                    <div className='title-text-container'>
                                            Type Id Plainte
                                    </div>
                                </div>
                                <div className='vertical-line'>
                                </div>
                            </div>
                        )}

                        <div className='row-content-reduced'>
                            <div className='date-complaint'>
                                <div className='title-text-container'>
                                        Date
                                </div>
                            </div>
                            <div className='vertical-line'>
                            </div>
                        </div>
                        {(windowWidth > 450) && (
                        <div className='row-content-reduced'>
                            <div className='last-drink-complaint'>
                                <div className='title-text-container'>
                                        Dernière boisson
                                </div>
                            </div>
                            <div className='vertical-line'>
                            </div>
                        </div>
                        )}
                        <div className='row-content-reduced'>
                            <div className='content-complaint'>
                                <div className='title-text-container'>
                                    <div className='title-text'>
                                        Contenu Plainte
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : 
                (
                    <div className={`row-content ${lastRow ? "last-row" : ""}`}>
                        {(windowWidth > 950) && (
                            <div className='row-content-reduced'>
                                <div className='id-complaint'>
                                    <div className='row-text'>
                                        {complaintData.id}
                                    </div>
                                </div>
                                <div className='vertical-line'>
                                </div>
                            </div>
                        )}
                        
                        {(windowWidth > 850) && ( 
                            <div className='row-content-reduced'>
                                <div className='type-id-complaint'>
                                    <div className='row-text'>
                                        {complaintData.complaintId}
                                    </div>
                                </div>
                                <div className='vertical-line'>
                                </div>
                            </div>
                        )}
                        <div className='row-content-reduced'>
                            <div className='date-complaint'>
                                <div className='row-text'>
                                    {complaintData.date}
                                </div>
                            </div>
                            <div className='vertical-line'>
                            </div>
                        </div>
                        
                        {(windowWidth > 450) && ( 
                        <div className='row-content-reduced'>
                            <div className='last-drink-complaint'>
                                <div className='row-text'>
                                    {complaintData.lastDrinkMachine}
                                </div>
                            </div>
                            <div className='vertical-line'>
                            </div>
                        </div>
                        )} 
                        <div className='row-content-reduced'>
                            <div className='content-complaint'>
                                <div className='row-text'>
                                    {complaintData.complaintContent}
                                </div>
                            </div>
                        </div>
                    </div>
                ) 
            }
        </div>

    )
}

export default (RowTableTechnicalDashboard)
