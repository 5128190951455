import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import '../../styles/dashboard/dashboardPage.css'
import TopBar from '../../utils/topBar/topBar'
import AddMachineModal from '../../utils/dashboard/mainDashboard/modals/addMachineModal'
import { reload } from '../../store/actions/reload';
import LoadingSpinner from '../loadingSpinner';
import AllMachineMainDashboard from '../../utils/dashboard/mainDashboard/allMachinesMainDashboard';
import { fetchAllMachinesData } from '../../store/actions/machine';



const MainDashboardPage = (props) => {
    const { reload } = props
    const userId = props.userId

    // we fetch the user data and the machines data with the data of their corresponding owner and gym
    useEffect(() => {
        reload(userId)
    }, [reload, userId])

    useEffect(() => {
        if (!props.allMachinesDataFetched) {
            props.fetchAllMachinesData()
        }   
    }, [props.allMachinesDataFetched])

    useEffect(() => {
        // Set up interval for subsequent fetches of the active machines
        const intervalId = setInterval(() => {
            reload(userId);
        }, 10 * 1000); // 5 minutes in milliseconds
    
        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array means it will only run once after the initial render


    if (props.allMachinesDataFetched) {
        return (
            <div className='main-container'>
                <div className='topBar-main-container'>
                    <div className='topBar-left-container'></div>
                    <TopBar />
                </div>
                <div className='main-container-content scrollable'>
                    <AllMachineMainDashboard allMachinesData={props.allMachinesData}/>
                </div>
                <AddMachineModal />
            </div>
        )
    }
    else {
        return (<LoadingSpinner />)
    }
}

const mapStateToProps = (state) => ({
    userId: state.userState.userId, 
    allMachinesData: state.machineState.allMachinesData, 
    allMachinesDataFetched: state.machineState.allMachinesDataFetched
})
  
const mapDispatchProps = (dispatch) => {
    return { 
        reload: (userId) => { dispatch(reload(userId)) }, 
        fetchAllMachinesData: () => { dispatch(fetchAllMachinesData()) }
    };
}
  
export default connect(mapStateToProps, mapDispatchProps)(MainDashboardPage);
