import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


export function fetchAllGymsData() {
    // fetch the info of all machines and the info the clientId who owns the machine
    return (dispatch) => {
        const gyms = [{'id': 0, 'name': 'Nouvelle salle'}]

        // Use a Firestore query to filter machines by "clientId"
        firebase.firestore()
            .collection("gyms")
            .orderBy("name")
            .get()
            .then((gymQuerySnapshot) => {
                gymQuerySnapshot.forEach((gym) => {
                    const data = gym.data()
                    const id = gym.id
                    let gymData = {id, ...data}
                    gyms.push(gymData)
                })

                dispatch({ type: "FETCH_GYMS_INFO", gyms: gyms });
            })
            .catch((error) => {
                dispatch({ type: 'ERROR_FETCHING_GYMS_INFO', error: error });
            });
    };
}