import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


export function fetchAllOwnersData() {
    // fetch the info of all machines and the info the clientId who owns the machine
    return (dispatch) => {
        const owners = [{'id': 0, 'name': 'Nouveau propriétaire'}]

        // Use a Firestore query to filter machines by "clientId"
        firebase.firestore()
            .collection("ownerMachines")
            .orderBy("name")
            .get()
            .then((ownerQuerySnapshot) => {
                ownerQuerySnapshot.forEach((owner) => {
                    const data = owner.data()
                    const id = owner.id
                    let ownerData = {id, ...data}
                    owners.push(ownerData)
                })

                dispatch({ type: "FETCH_OWNERS_INFO", owners: owners });
            })
            .catch((error) => {
                dispatch({ type: 'ERROR_FETCHING_OWNERS_INFO', error: error });
            });
    };
}