import React, { useEffect, useState } from 'react'
import "../styles/navbar.css"
import Logo from "../assets/images/Fiilin_logo.png"
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



const NavBar = (props) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [selectedPage, setSelectedPage] = useState('/')
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleClickRoute = (path) => {
        navigate(path)
        setIsMenuOpen(false); // Close the menu when a route is selected
    }

    // Toggle the menu state
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // we need to update the selectedPage everytime a new page is selected
    useEffect(() => {
        setSelectedPage(location.pathname)
    }, [location.pathname]);

    // Update windowWidth when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (windowWidth < 768) {
        return (
            <div className='narbar-fixed'>
                { isMenuOpen ? 
                    (
                        <div className='navbar-links'>
                            <div className='navbar-links-container'>
                                <button onClick={() => handleClickRoute('/')} className={`navbar-text-content ${(selectedPage === "/") || (selectedPage.includes("/machineDetails/")) ?  "navbar-text-content-selected" : ""} `}>Pilotage</button>
                                <button onClick={() => handleClickRoute('/finance')} className={`navbar-text-content ${selectedPage === "/finance" ?  "navbar-text-content-selected" : ""} `}>Rapports Finance</button>
                                <button onClick={() => setIsMenuOpen(false)} className="navbar-text-content">X</button>
                            </div>
                        </div>
                    ) :
                    (
                        <div className='phone-navbar-container'>
                            <button onClick={() => navigate('/')} className='brand-loggo' >
                                <img src={Logo} alt="logo" className='logo-image'></img>  
                            </button>
                            <button onClick={toggleMenu} className='menu-icon'>
                                <div className="burger"></div>
                                <div className="burger"></div>
                                <div className="burger"></div>
                            </button>
                        </div>
                    )
                }                
            </div>
        )
    } else {
        return (
            <nav className="navbar-fixed">
                <div className='center-link-navbar'>
                    <button onClick={() => navigate('/')} className='brand-loggo' >
                        <img src={Logo} alt="logo" className='logo-image'></img>  
                    </button>
                    <div className='navbar-text-container'>
                        <button onClick={() => handleClickRoute('/')} className={`navbar-text-content ${(selectedPage === "/") || (selectedPage.includes("/machineDetails/")) ?  "navbar-text-content-selected" : ""} `}>Pilotage</button>
                        <button onClick={() => handleClickRoute('/finance')} className={`navbar-text-content ${selectedPage === "/finance" ?  "navbar-text-content-selected" : ""} `}>Rapports Finance</button>
                    </div>
                </div>
            </nav>
        )
    }
    
}

export default NavBar