import React from 'react';
import {RotatingLines} from 'react-loader-spinner';
import '../styles/loadingSpinner.css'; // Create a separate CSS file for styling

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <RotatingLines strokeColor="#f08300" height={60} width={60} visible={true} animationDuration='1.25'/>
    </div>
  );
};

export default LoadingSpinner;
