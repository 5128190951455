import { fetchInfoUser } from "./user"
import {fetchAllMachinesData} from "./machine"
import { fetchAllOwnersData } from "./owners"


export function reload(clientId) {
    // this method calls all the methods that fetch data at onces
    return ((dispatch) => {
        dispatch(fetchAllMachinesData())
        dispatch(fetchInfoUser(clientId))
        dispatch(fetchAllOwnersData())
    })
}
  