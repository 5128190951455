const initState = {
    isAddMachineModalOpened: false, 
    selectedDashboardData: null,
}

const dashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case 'OPEN_ADD_MACHINE_MODAL':
            console.log("add machine modal successfully opened")
            return {
                ...state, 
                isAddMachineModalOpened: true, 
            }
        case 'CLOSE_ADD_MACHINE_MODAL':
            console.log("refill modal successfully closed")
            return {
                ...state, 
                isAddMachineModalOpened: false, 
            }
        case 'SELECTED_DATA_DASHBOARD':
            console.log("dashboard data successfully selected")
            return {
                ...state, 
                selectedDashboardData: action.data, 
            }
        default:
            return state
    }
}

export default dashboardReducer