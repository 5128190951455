const initState = {
    userId: null,
    userDataFetch: false, 
    firstName: '', 
    lastName: '',
    email: '',
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_USER_ID':
            return {
                ...state,
                userId: action.userId, 
            };
        case 'FETCH_USER_DATA': 
            return {
                ...state, 
                firstName: action.currentUser.firstName, 
                lastName: action.currentUser.lastName,
                email: action.currentUser.email, 
                stripeCustomerId: action.currentUser.stripeCustomerId,
                countCurrentOrders: action.currentUser.countCurrentOrders, 
                userDataFetch: true, 
            }
        default:
            return state
    }
}

export default userReducer