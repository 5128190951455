// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC_J9LrHeIvlh0CBDRoIhg9ijA928PmBAQ",
    authDomain: "fiilin-c1d08.firebaseapp.com",
    projectId: "fiilin-c1d08",
    storageBucket: "fiilin-c1d08.appspot.com",
    messagingSenderId: "904023614425",
    appId: "1:904023614425:web:ab8e7adda036ba5cae2fc8",
    measurementId: "G-4ZRETQVFL2"
  };
  
  export { firebaseConfig }