import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


export function fetchProductInfo(product, familyRef, docId) {
    const familyPromise = familyRef
    .get();

    const productRef = familyRef.collection("flavors");

    // Query the description collection for each product
    const productPromise = productRef
    .doc(docId)
    .get();

    const descriptionPromise = productRef
    .doc(docId)
    .collection("description")
    .orderBy("order")
    .get();

    // Query the composition collection for each product
    const compositionPromise = productRef
    .doc(docId)
    .collection("composition")
    .orderBy("order")
    .get();

    // Query the usage collection for each product
    const usagePromise = productRef
    .doc(docId)
    .collection("usage")
    .orderBy("order")
    .get();

    // Combine all the promises for description, composition, and usage for each product
    const combinedPromise = Promise.all([familyPromise, productPromise, descriptionPromise, compositionPromise, usagePromise]).then(
        ([snapshotFamily, snapshotProduct, snapshotDescription, snapshotComposition, snapshotUsage]) => {
            const dataFamily = snapshotFamily.data()

            const dataProduct = snapshotProduct.data()

            const description = snapshotDescription.docs.map((docDescription) => {
                const id = docDescription.id;
                const dataDescription = docDescription.data();
                return { id, ...dataDescription };
            });

            const composition = snapshotComposition.docs.map((docComposition) => {
                const id = docComposition.id;
                const dataComposition = docComposition.data();
                return { id, ...dataComposition };
            });

            const usage = snapshotUsage.docs.map((docUsage) => {
                const id = docUsage.id;
                const dataUsage = docUsage.data();
                return { id, ...dataUsage };
            });

            return { ...product, ...dataFamily, ...dataProduct, description, composition, usage }; // Combine product data with description, composition, and usage 
        }
    );
    return combinedPromise
}

export function fetchGymInfo(machineData, complaints, gymId, ownerId) {

    // Query the description collection for each product
    const gymPromise = firebase.firestore().collection("gyms")
    .doc(gymId)
    .get();

    const ownerMachinePromise = firebase.firestore().collection("ownerMachines")
    .doc(ownerId)
    .get();

    // Combine all the promises for description, composition, and usage for each product
    const combinedPromise = Promise.all([gymPromise, ownerMachinePromise]).then(
        ([snapshotGym, snapshotOwnerMachine]) => {
            const dataGym = snapshotGym.data()
            const dataOwnerMachine = snapshotOwnerMachine.data()
            const necessaryDataGym = {'nameGym': dataGym.name, 'phoneGym': dataGym.phone, 'addressGym': dataGym.address}
            const necessaryDataOwnerMachine = {'nameOwner': dataOwnerMachine.name, 'phoneOwner': dataOwnerMachine.phone}
            return { ...machineData, ...necessaryDataGym, ...necessaryDataOwnerMachine, ...complaints }; // Combine client and owner data
        }
    );
    return combinedPromise
}