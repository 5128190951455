import React from 'react'
import '../../styles/topBar/topBar.css'
import ProfileIconTopBar from './profileIcon'
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

const TopBar = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const initials = props.userFirstName.slice(0, 1) + props.userLastName.slice(0, 1)
    // Update windowWidth when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (windowWidth < 768) {
        return null
    } else {
        return (
            <div className="topBar-container">
                <div className="topBar-items">
                    <ProfileIconTopBar initials={initials}/>
                </div>
            </div>
        
    )
    }
    
}

const mapStateToProps = (state) => ({
    userFirstName: state.userState.firstName, 
    userLastName: state.userState.lastName
})
  
  
export default connect(mapStateToProps, null)(TopBar);
